import { nextTick } from 'vue'
import schttp from 'public/src/services/schttp'
const { langPath } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  data() {
    return {
      isShowOverTimeLimit: false,
      isShowSign: false,
      isShowOldSign: false,
      returnItemIsShow: false,
      returnItemBillno: '',
      returnItemBtnPosition: '',
      module_name: 'OrderDetail', // OrderDetail or OrderList
      isShowReturnUnavailableModal: false,
      unavailableModal: false,
      abTestConfig: {},
      returnOrderInfo: {},
      returnMixinAbt: {},
      showExpirePop: false
    }
  },
  methods: {
    toggleReturnUnavailableModal() {
      this.isShowReturnUnavailableModal = !this.isShowReturnUnavailableModal
    },
    toggleUnavailableModal() {
      this.unavailableModal = !this.unavailableModal
    },
    checkSiteItem(orderInfo) {
      return this.checkSiteFn(orderInfo)
    },
    async handleReturnItem ({ orderInfo, pageFrom, position, isReturnOff }) {
      this.returnOrderInfo = orderInfo
      this.module_name = pageFrom
      this.returnItemBillno = orderInfo.billno
      this.returnItemBtnPosition = position

      if (isReturnOff) {
        this.returnItemEventData('other')
        this.toggleReturnUnavailableModal()
        return
      }
      // 降级
      if (orderInfo.voluntaryReturnType == 2) {
        this.returnItemEventData('other')
        this.getRefundTip(orderInfo.shipping_country_id)
        return
      }

      const result = await this.checkSiteItem(orderInfo)
      if (result != 1) {
        this.returnItemEventData('other')
        return this.returnJump(result)
      }

      const { over_time_limit: isOverTimeLimit, is_signed: isSigned } = orderInfo
      // 是否超退货时效
      if (Number(isOverTimeLimit)) {
        this.returnItemEventData('overtime')
        return this.toggleShowOverTimeLimit()
      }
      // 是否签收
      if (Number(isSigned) !== 1) {
        this.returnItemEventData('undelivered')
        return this.toggleReturnItemSign('popup')
      }

      this.returnItemEventData('alldelivered')
      this.returnItemJumpEventData('alldelivered')
      this.$router.push(`${langPath}/orders/return/${orderInfo.billno}?pf=listordetail`)
    },
    returnItemJumpEventData (scene = '') {
      daEventCenter.triggerNotice({
        daId: this.module_name == 'OrderDetail' ? '1-19-1-158' : '1-20-1-174',
        extraData: {
          position: this.returnItemBtnPosition,
          scene
        }
      })
    },
    returnItemEventData (scene = '') {
      daEventCenter.triggerNotice({
        daId: this.module_name == 'OrderDetail' ? '1-19-1-156' : '1-20-1-172',
        extraData: {
          scene
        }
      })
    },
    toggleReturnItemSign (action) {
      let saActivityName = {
        popup: 'expose_popup_auto_return_tips',
        no: 'click_popup_auto_return_tips_no',
        close: 'click_popup_auto_return_tips_close',
        yes: 'click_popup_auto_return_tips_yes'
      }

      sa('send', {
        activity_name: saActivityName[action],
        activity_param: {
          order_id: this.returnItemBillno
        }
      }, { beacon: 1 })

      if (this.returnMixinAbt?.showDoubleCheck) {
        this.isShowSign = !this.isShowSign
      } else {
        this.isShowOldSign = !this.isShowOldSign
      }

      if (action === 'yes') {
        this.returnItemJumpEventData('undelivered')
        this.$router.push(`${langPath}/orders/return/${this.returnItemBillno}?pf=listordetail`)
      }
    },
    returnJump(result) {
      this.jumpReturn(this.returnOrderInfo, result)
    },
    toggleShowOverTimeLimit() {
      sa('send', {
        activity_name: this.isShowOverTimeLimit
          ? 'click_popup_non_returnable_ok'
          : 'expose_popup_non_returnable',
        activity_param: {}
      })

      if (this.returnMixinAbt?.showDoubleCheck) {
        this.showExpirePop = !this.showExpirePop
        return
      }
      this.isShowOverTimeLimit = !this.isShowOverTimeLimit
    },
    /*
     *
     *非{Waiting for Payment(0,12)、Awaiting Verification(13)、pending(16)、paid(1)、Revoked(8)、Rejected(9)、canceled(3)、Refunded(11)、Expired(2)}时，查退货中心接口
     */
    getOrderReturn(orders) {
      let newOrders = []
      const data = []
      if (orders) {
        this.module_name = 'OrderList'
        newOrders = orders
      } else {
        this.module_name = 'OrderDetail'
        newOrders = [this.orderInfo]
      }

      newOrders.forEach(item => {
        if ([5, 7, 10, 18].includes(item.orderStatus)) {
          data.push({
            billno: item.billno
          })
        }
      })
      if (!data.length) {
        return
      }
      this.csasQueryOrderReturn(data)
    },
    async csasQueryOrderReturn(data) {
      const queryData = data?.map(item => item.billno)
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/return/checkOrderReturn/query',
        data: {
          billnos: queryData.join(',')
        }
      })
      if (res.code == 0) {
        const orders = (res.info && res.info.orderList) || []
        this.orders.forEach((item, index) => {
          orders.forEach(i => {
            if (item.billno == i?.billno) {
              this.orders[index].returnable = i.returnable // 订单是否可退 0:否 1:是
              this.orders[index].over_time_limit = i.overTimeLimit // 是否超退货时效 0:否 1:是
              this.orders[index].is_signed = i.isReceived // 是否已签收 0:否 1:是
              this.orders[index].voluntaryReturnType = i.voluntaryReturnType // 自主退货类型 0:停用 1:启用 2:降级
            }
          })
        })
        nextTick(() => {
          this.adjuctOrderListMore()
          $(window).scroll()
        })
      }
    },
    async getRefundTip(shipping_country_id) {
      const submitData = {
        desc_type: [17],
        country_id: shipping_country_id
      }
      let res = await schttp({
        method: 'POST',
        url: '/api/orders/return/getRefundTip/get',
        data: submitData
      })
      if (res.code == 0 && res.info) {
        const return_desc_data = res.info.return_desc_data || []
        return_desc_data.forEach(item => {
          if (item.desc_type == 17) {
            this.showMaskTips(item.refund_desc || '')
          }
        })
      }
    },
    setReturnMixinAbt(abtInfo) {
      this.returnMixinAbt = abtInfo
    }
  }
}
