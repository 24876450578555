import usePaymentAbt from 'public/src/pages/checkout/hooks/usePaymentAbt'
import { usePayInstance } from 'public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/hook/usePayInstance.js'
import { usePaymentBackTips } from '@/public/src/pages/user/child_pages/orders_bff/common/pay_toolkit/hook/usePaymentBackTips.js'
import useVuex from 'public/src/pages/user/hooks/useVuex.js'
import { useOrderPayment } from './hook/useOrderPayment'
import { onBeforeUnmount, onDeactivated, onActivated } from 'vue'

export const usePaymentMixin = (storeNameSpace = 'orderDetail') => {
  const { paymentCustomizeFlow, codFreeShow, applePayCashShow } = usePaymentAbt()
  const { useMapActions, useMapState, useMapMutations, useMapGetters } = useVuex(`${storeNameSpace}/payAgain`)

  const { fetchBffOrderInfo, resetPayAgainState } = useMapActions(['fetchBffOrderInfo', 'resetPayAgainState'])
  const { assignOldOrderInfo, updatePayAgainStatus, updateCreateOrderBinDialog } = useMapMutations(['assignOldOrderInfo', 'updateBffOrderInfo', 'updatePayAgainStatus', 'updateCreateOrderBinDialog'])
  const { selectedPaymentInfo, bffOrderInfo, payAgainStatus, createOrderBinDialog } = useMapState(['selectedPaymentInfo', 'bffOrderInfo', 'payAgainStatus', 'createOrderBinDialog'])
  const { isShowPaypalButton } = useMapGetters(['isShowPaypalButton'])

  const { paymentInstance } = usePayInstance()
  const {
    paymentBackTipsRef,
    handleBackTipsMounted
  } = usePaymentBackTips()

  const { 
    reportClickPlaceOrderAnalytics, 
    updateOrderFn,
    handleStartPaymentProcess,
    handleUpdatePayment,
    handleSelectPayment,
    handleSetValidateByPayFn,
    handleSetResetBsPayTookitStatusFn,
    handleUpdatePaymentChange,
    asyncUpdateOrder,
    handleCreatePayment: _handleCreatePayment,
  } = useOrderPayment({ storeNameSpace })

  const handleCreatePayment = ({ extraPayInfo } = {}) => {
    console.log('order_new---___handleCreatePayment', selectedPaymentInfo.value?.code)
    _handleCreatePayment({
      paymentInfo: selectedPaymentInfo.value,
      extraPayInfo
    })
  }

  let resolveFn = null

  let awaitFn = new Promise(resolve => {
    resolveFn = resolve
  })

  // 需要等待数据/支付方式列表组件等初始化完成后再执行后续逻辑
  const handleDirectCreatePayment = async () => {
    console.log('order_new---handleDirectCreatePayment', selectedPaymentInfo.value?.code, resolveFn)
    if (selectedPaymentInfo.value?.code && resolveFn) {
      resolveFn?.()
    }
    await awaitFn
    console.log('order_new---handleDirectCreatePayment--res', selectedPaymentInfo.value?.code)
    handleCreatePayment()
  }
  // 用于初始化选中的支付方式等数据，并处理后续逻辑
  const awaitInitSelectPaymentInfo = ({ paymentInfo }) => {
    console.log('order_new---awaitInitSelectPaymentInfo', selectedPaymentInfo.value?.code, paymentInfo?.code)
    if (selectedPaymentInfo.value?.code && selectedPaymentInfo.value?.code === paymentInfo?.code) {
      resolveFn?.()
      return
    }
    if (paymentInfo?.code) {
      handleSelectPayment(paymentInfo)
      handleUpdatePayment()
    }
    resolveFn?.()
  }

  onActivated(() => {
    resolveFn = null
    awaitFn = new Promise(resolve => {
      resolveFn = resolve
    })
  })

  onBeforeUnmount(() => {
    resetPayAgainState()
  })

  onDeactivated(() => {
    resetPayAgainState()
  })

  
  return {
    isShowPaypalButton,
    payAgainStatus,
    bffOrderInfo,
    selectedPaymentInfo,
    paymentCustomizeFlow,
    applePayCashShow,
    codFreeShow,
    paymentInstance,
    fetchBffOrderInfo,
    resetPayAgainState,
    reportClickPlaceOrderAnalytics,
    updateOrderFn,
    handleStartPaymentProcess,
    handleUpdatePayment,
    handleSelectPayment,
    handleSetValidateByPayFn,
    handleUpdatePaymentChange,
    asyncUpdateOrder,
    handleCreatePayment,
    handleDirectCreatePayment,
    awaitInitSelectPaymentInfo,
    assignOldOrderInfo,
    updatePayAgainStatus,
    paymentBackTipsRef,
    handleBackTipsMounted,
    handleSetResetBsPayTookitStatusFn,
    createOrderBinDialog,
    updateCreateOrderBinDialog
  }
}
